import React,{useState} from 'react'
import { json, useNavigate } from 'react-router-dom';
 import CryptoJS from 'crypto-js';
 import {myGlobal,api} from '../Global'


export default function Login() {

      const history = useNavigate();
         
    const [email, setEmail] = useState();
    const [emailactive, setEmailactive] = useState("0");

        const [pass, setPass] = useState();
        const [passactive, setPassactive] = useState("0");

    const [showloading, setshowLoading] = useState(false);



          const changemail = (e) => {        
             
       if(e.target.value!=="")
            {
               setEmailactive("2")
            }  
            else{
                setEmailactive("1")
            }   


            
            setEmail(e.target.value)
        }

          const changecpass = (e) => {        
             
            if(e.target.value!=="")
            {
               setPassactive("2")
            }  
            else{
                setPassactive("1")
            }     

            setPass(e.target.value)           
        }

          const  registerme = async() => { 

           
        
        if(emailactive==="2" &&  pass !==""  )     
          {

             
              setshowLoading(true)
                //setshowLoading(true)

               let username=email;
              let password=pass;
              
              let passdata="grant_type=password&";
              passdata=passdata+"username="+username+"&";
              passdata=passdata+"password="+password+"&";
              passdata=passdata+"grant_claims=0";
           

               fetch(api+'/token', {
                  method: 'POST',
                  headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/x-www-form-urlencoded',
                  },
                  body: passdata
              })
              .then((response) => response.json())
              .then((responseData) => {

                //"error":"Wrong Crendtials","error_description":"Provided username and password is incorrect"
                //alert(JSON.stringify(responseData))
                 if(responseData.access_token === undefined)
                    {
                       alert("Invalid email or password");
                      setshowLoading(false)
                    }
                    else{

                      console.log(JSON.stringify(responseData))
                         localStorage.setItem("custid",CryptoJS.AES.encrypt(responseData.ids, myGlobal).toString())
                        localStorage.setItem("username",CryptoJS.AES.encrypt(email, myGlobal).toString())
                        localStorage.setItem("password",CryptoJS.AES.encrypt(pass, myGlobal).toString())
                        localStorage.setItem("token",CryptoJS.AES.encrypt(responseData.access_token, myGlobal).toString())
                        localStorage.setItem("name",CryptoJS.AES.encrypt(responseData.name, myGlobal).toString())
                        localStorage.setItem("desi",CryptoJS.AES.encrypt(responseData.designation, myGlobal).toString())
                        localStorage.setItem("hdid",CryptoJS.AES.encrypt(responseData.hd_id, myGlobal).toString())
                         
                        

                        // malert.success("You have sucessfully Logged-in!")
                         history("../dashboard");

                    }
                     
                 
                     setshowLoading(false)
              });

        

            
            
        }
        else
        {


             if(emailactive==="0")
          {
            setEmailactive("1");
          }


          if(passactive==="0")
          {
            setPassactive("1");
          }
         
                // setshowLoading(false)
        }       

    }



    
         const  login = async() => { 

           

          if(emailactive==="2" &&  pass !==""  )     
          {
               

             setshowLoading(true)
    
                        fetch(api+"/login.php",            {
                  method: 'POST',   
               
                  headers: {
                    'Accept': 'application/json',
                   
                    
                  },
                 body: JSON.stringify({username: email, password: pass})
                  }).then((Response) => Response.text())
                  .then((responseData) => {


                    // if(responseData.error === "Invalid User")
                    // {
                    //    alert("Invalid email or password");
                    //   setshowLoading(false)
                    // }
                    // else{

                     

                    // }

                   
                   
                    responseData=JSON.parse(responseData)

                  

                    try {

                   
                    if(responseData.error !== ""){

                 
                        
                        localStorage.setItem("username",CryptoJS.AES.encrypt(email, myGlobal).toString())
                        localStorage.setItem("password",CryptoJS.AES.encrypt(pass, myGlobal).toString())
                        localStorage.setItem("token",CryptoJS.AES.encrypt(responseData.token, myGlobal).toString())
                        localStorage.setItem("name",CryptoJS.AES.encrypt("Admin", myGlobal).toString())
                       
                         
                        

                       
                         history("../dashboard");

                    }
                     else if(responseData.error === "Invalid User")
                    {
                       alert("Invalid email or password");
                      setshowLoading(false)
                    }

                    }
                    catch(s)
                    {

                       alert(s)

                      

                    }


                     setshowLoading(false)


                  }).catch(error => {
                         setshowLoading(false)
                       
                          alert(error)
                })
              
                 



          }
          else{

                
             if(emailactive==="0")
          {
            setEmailactive("1");
          }


          if(passactive==="0")
          {
            setPassactive("1");
          }

          }

        }

   React.useEffect(()=>
        {

            document.title="Login | Hariom Textiles "

          localStorage.clear();
        }, [])
        

    return (
       
       <>
            <main style={{backgroundImage:'url(https://www.textileinfomedia.com/images/og-category/textile-mill.jpg)'}}>
    <div className="container">

      <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
        <div className="container">
          <div className="row">
          <div className="col-lg-7">
          {/* <img src='../assets/img/girl.png' alt='login' style={{height:'80vh'}} /> */}
          </div>
            <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

              <div className="d-flex justify-content-center py-4">
                <a href="index.html" className="logo d-flex align-items-center w-auto">
                 
                  {/* <span className="d-none d-lg-block">CASCG</span> */}
                </a>
              </div>

              <div className="card mb-3">

                <div className="card-body">

                  <div className="pt-4 pb-2 text-center">
                     <img src="https://demo.hariomtextiles.co.in/img/logo.png"  style={{width:'200px'}}  alt=""/>
                    {/* <h5 className="card-title text-center pb-0 fs-4">Login to Your Account</h5>
                    <p className="text-center small">Enter your username & password to login</p> */}
                  </div>

                  <div className="row g-3 needs-validation">

                    <div className="col-12">
                      <label for="yourUsername" className="form-label">Username</label>
                      <div className="input-group has-validation">
                      
                       <input type="text" placeholder="email"  value={email} className={emailactive==='0' ? 'form-control' : emailactive==='1' ? 'form-control is-invalid' : 'form-control is-valid'  }  onChange={(e) => {changemail(e)}}  id="inputEmail"  />

                        <div className="invalid-feedback">Please enter your username.</div>
                      </div>
                    </div>

                    <div className="col-12">
                      <label for="yourPassword" className="form-label">Password</label>
                      <input type="password" value={pass}
                      
                       onKeyPress={(e) => {
                        if (e.key === "Enter") 
                        {
                         
                        login()
                             
                            }
                        }
                    }
                      
                       className={passactive==='0' ? 'form-control' : passactive==='1' ? 'form-control is-invalid' : 'form-control is-valid'  } onChange={(e) => {changecpass(e)}} maxLength="15" id="formGroupExampleInput" placeholder="Confirm Password" />

                      <div className="invalid-feedback">Please enter your password!</div>
                    </div>

                    {/* <div className="col-12">
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" name="remember" value="true" id="rememberMe"/>
                        <label className="form-check-label" for="rememberMe">Remember me</label>
                      </div>
                    </div> */}
                    <div className="col-12">
                      {/* <button className="btn btn-primary w-100"  onClick={registerme} type="submit">Login</button> */}
                      <button className="btn  w-100" style={{backgroundColor:'#000',color:'#fff'}} type="button" onClick={login}>
      { showloading===true ?
      <>
  <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
  <span class="sr-only"> Please Wait...</span>
  </>
        :
  <span class="sr-only">Login</span>
      }
</button>

                    </div>
                    {/* <div className="col-12">
                      <p className="small mb-0">Don't have account? <a href="pages-register.html">Create an account</a></p>
                    </div> */}
                  </div>

                </div>
              </div>

              <div className="credits">
                
                Designed by <a href="https://easysoftstech.com/">Easysofts Tech</a>
              </div>

            </div>
            <div className="col-lg-1">

          </div>
          </div>
        </div>

      </section>

    </div>
  </main>
       </>
    )
}

import {
  BrowserRouter as Router,
  Routes,
  Route,

  
} from "react-router-dom";
import Dashboard from "./Adminpanel/Dashboard";
import Login from "./Adminpanel/Login";
import Logout from "./Adminpanel/Logout";
import Changepass from './Adminpanel/Changepassword'
import Addblog from "./Adminpanel/Addblog";
import Bloglist from "./Adminpanel/Bloglist";
import Editblog from "./Adminpanel/Editblog";
import Careerlist from "./Adminpanel/Careerlist";
import Addcareer from "./Adminpanel/Addcareer";
import Editcareer from "./Adminpanel/Editcareer";

function App() {
  return (
    
      <Router >
    <div className="App">


     <Routes>
        

      
    <Route exact path="/" element={<Login/>}/>
        <Route exact path="/dashboard" element={<Dashboard/>}/>

                <Route exact path="/addproduct" element={<Addblog/>}/>
                 <Route exact path="/addmenu" element={<Addcareer/>}/>

                     <Route exact path="/productlist" element={<Bloglist/>}/>
                      <Route exact path="/menulist" element={<Careerlist/>}/>

                             <Route exact path="/editproduct" element={<Editblog/>}/>
                              <Route exact path="/editmenu" element={<Editcareer/>}/>
  

            <Route exact path="/change-password" element={<Changepass/>}/>
       <Route exact path="/logout" element={<Logout/>}/>
      </Routes>

      </div>

      </Router>


  );
}

export default App;


import Header from '../Components/Header'
import Sidebar from '../Components/Sidebar'
import React,{useState} from 'react'
import DataTable  from 'react-data-table-component';


import { Link, useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js';
import {myGlobal,api} from '../Global'


export default function Bloglist() {

const history=useNavigate();


 const [showloading,setShowloading] = useState(false);
 const [searchquery,setsearchquery] = useState([]);
 const [space,setspace] = useState([]);
 const [loginlist,setLoginlist] = useState([]);

   const  search = (e) => {
  setsearchquery(e.target.value)
  //   setspace(clientlist.filter(e => clientlist.proname.match(/.*p.*/)))

 setspace( loginlist.filter(function (el) {
  return el.head.toLowerCase().match(e.target.value.toLowerCase())
        ;
}))


  }

       const columns = [
    //   {
    //     name: 'EMP CODE',
    //     selector: row => "EMP-"  +row.lginid,
    // },
    {
        name: 'Title',
        selector: row => row.head,
    },

     {
        name: 'Menu',
        selector: row => row.heading,
    },

     {
        name: 'Product Sequence Number',
        selector: row => row.indexs,
    },
    // {
    //     name: 'Date',
    //     selector: row => row.dates,
    // },    
   
      
 
   
     {
      cell: (row) => <Link to={"/editproduct"}  state={{lginid:row.blog_id,manager:row.head}} className="button-8"  >Edit</Link>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
     
    
];
 

           const  getmenudata = async() => { 

        setShowloading(true)

      let bytes  = CryptoJS.AES.decrypt(localStorage.getItem("token"), myGlobal);
  
      let authorization=bytes.toString(CryptoJS.enc.Utf8);

       authorization="Bearer "+authorization;

    

       fetch(api+"/bloglist.php",            {
                  method: 'GET',   
               
                  headers: {
                    'Accept': 'application/json',     
                    'Authorization': authorization
                  },
                
                }).then((response) => response.text())
                  .then((response) => {
                          
                    
                     setShowloading(false)

                  
                    

                        try{

                          setLoginlist(JSON.parse(response))
                         setspace(JSON.parse(response))

                          if(response.message==="Access denied")  
                          {
                              history('../logout')
                          }

                        }
                        catch(f)
                        {
                               setLoginlist(JSON.parse(response))
                         setspace(JSON.parse(response))
                          }
                          
                         




                        setShowloading(false)
       
                  }).catch(error => {
                         setShowloading(false)
                         
                             alert(error)
                           
                })

    }



                React.useEffect(() => 
  {
 document.title = "Product List"
      document.body.classList.remove('toggle-sidebar')  

getmenudata()
    //  getproductdata()
   
    
  }, [])


  return (
    <>
    <Header/>
    <Sidebar/>
    
            <main id="main" className="main mainremoveback">

<div class="pagetitle"><h1>Product List</h1></div>

    <section className="section">
      <div className="row">
        <div className="col-lg-12">

          <div className="card">
            <div className="card-body">

                     <div class="row g-3 mt-3">
                <div class="col-md-9">
<Link to="../addproduct"   >
<button type="button" class="button-9" data-bs-toggle="tooltip" data-bs-placement="top" title="Add Product">
  Add Product
</button>  </Link>
{showloading===true ?
 <div class="spinner-border text-dark" role="status" style={{width: '20px',
    height: '20px',
    marginLeft: '10px',borderWidth:'.8px',color:'#012970'}}>

</div>
:null}


                </div>
                
             
                <div class="col-md-3">
                 <input type="text" style={{maxWidth:'250px',border:'1px solid #767272de',color: 'rgba(0,0,0,0.87)',
    fontSize: '12px'}} value={searchquery} onChange={(e) => {search(e)}}   class="form-control" placeholder="Search by Name"  />
                </div>

                </div>
           
        <br/>

                <DataTable
                        columns={columns}
                        data={space}    
                      pagination                
                dense
                    />
              
             

            </div>
          </div>

        </div>
      </div>
    </section>

  </main>
    
    
    </>
  )
}

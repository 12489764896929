import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function Logout() {

    
      const history = useNavigate();
     

    React.useEffect(() => 
  {
    
        localStorage.clear();

 
                history("../");
      
  }, [history])


    return (
        <div>
            
        </div>
    )
}
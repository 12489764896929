
import Header from '../Components/Header'
import Sidebar from '../Components/Sidebar'
import React,{useState} from 'react'



import { Link, useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js';
import {myGlobal,api} from '../Global'


export default function Dashboard() {

const history=useNavigate();
 const [loadproduct, setloadproduct] = useState(false);

  const [customer, setcustomer] = useState("0");

 


                React.useEffect(() => 
  {
 document.title = "Dashboard"
      document.body.classList.remove('toggle-sidebar')  


    //  getproductdata()
   
    
  }, [])


  return (
    <>
    <Header/>
    <Sidebar/>
    
        <main id="main" className="main">

    <div className="pagetitle">
      <h1>Dashboard</h1>
      <nav>
        <ol className="breadcrumb">
         
          <li className="breadcrumb-item active">Dashboard</li>
        </ol>
      </nav>
    </div>
    
      {/* <section className="section dashboard">
      <div className="row">

       
        <div className="col-lg-12">
          <div className="row">

           
            <div className="col-xxl-4 col-md-6">
              <div className="card info-card sales-card">

            

                <div className="card-body">
                  <h5 className="card-title">Sales <span>| Today</span></h5>

                  <div className="d-flex align-items-center">
                   <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i class="bi bi-currency-dollar"></i>
                    </div>
                    <div className="ps-3">
                      <h6> 1500</h6>
                    
                    </div>
                  </div>
                </div>

              </div>
            </div>


                       <div className="col-xxl-4 col-md-6">
              <div className="card info-card sales-card">

            

                <div className="card-body">
                  <h5 className="card-title">Total Products <span></span></h5>

                  <div className="d-flex align-items-center">
                   <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i class="bi bi-people"></i>
                    </div>
                    <div className="ps-3">
                      <h6> {customer}</h6>
                      
                    </div>
                  </div>
                </div>

              </div>
            </div>

         
            

        
     
    
    </div>

    </div>

    </div>


    </section> */}

</main>

    </>
  )
}

import React,{useState} from 'react'
 import CryptoJS from 'crypto-js';
 import {myGlobal} from '../Global'
 import { useNavigate,Link } from 'react-router-dom';
import Sidebar from './Sidebar';

export default function Header() {
  const history = useNavigate();

  const [name, setName] = useState();
  const [desi, setDesi] = useState();
       const [displayprop,setDisplayprop]=useState("none");


       const  changecl = async() => { 

if(displayprop==="none")
{
  setDisplayprop("block")
} 
else{
   setDisplayprop("none")
}
      
}

        React.useEffect(() => 
  {
 
      try {
          let ciphertext = CryptoJS.AES.decrypt(localStorage.getItem("token"), myGlobal).toString(CryptoJS.enc.Utf8);

          setName(CryptoJS.AES.decrypt(localStorage.getItem("name"), myGlobal).toString(CryptoJS.enc.Utf8))
        
   
        if(ciphertext ==="")
        {
                history("../");
        }
       
      }
      catch(e)
      {
          history("../");
      }
  
  }, [history])



 const clicks = () =>{
    
    
    if(document.body.classList.contains('toggle-sidebar'))
    {       
        document.body.classList.remove('toggle-sidebar')
    }
    else{
         
            document.body.classList.add('toggle-sidebar')
    }
      
        
}     

 



    return (
        
        <>
       

              <header id="header" className="header fixed-top d-flex align-items-center">

    <div className="d-flex align-items-center justify-content-between">
      <Link to="index.html" className="logo d-flex align-items-center">
        <img src="https://demo.hariomtextiles.co.in/img/logo.png" 
        alt=""/>
        <span className="d-none d-lg-block" style={{marginTop: '22px', color:'#000',fontSize:'12px',fontWeight:'bold',textAlign:'center'}}>
        </span>
      </Link>
      <i className="bi bi-list toggle-sidebar-btn" onClick={clicks}></i>
    </div>


    <nav className="header-nav ms-auto">
      <ul className="d-flex align-items-center">


        <li className="nav-item dropdown pe-3">

          <Link className="nav-link nav-profile d-flex align-items-center pe-0" to="#" data-bs-toggle="dropdown" onClick={changecl}>
            <img src="assets/img/profile-img.jpg" alt="Profile" className="rounded-circle"/>
            <span className="d-none d-md-block dropdown-toggle ps-2">{name}</span>
          </Link>

          <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile" style={{display:`${displayprop}`}} >
            <li className="dropdown-header">
              <h6>{name}</h6>
             
            </li>
            <li>
              <hr className="dropdown-divider"/>
            </li>

            <li>
              <Link className="dropdown-item d-flex align-items-center" to="../change-password">
                <i className="bi bi-person"></i>
                <span>Change Password</span>
              </Link>
            </li>
            <li>
              <hr className="dropdown-divider"/>
            </li>


            <li>
              <hr className="dropdown-divider"/>
            </li>

            <li>
              <Link className="dropdown-item d-flex align-items-center" to="../logout">
                <i className="bi bi-box-arrow-right"></i>
                <span>Sign Out</span>
              </Link>
            </li>

          </ul>
        </li>

      </ul>
    </nav>

  </header>
        </>
    )
}
